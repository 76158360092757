import Banner from "assets/images/vnpt_banner.jpg";
import "./header.scss";
import { useSelector } from "react-redux";
import { PATH_IMAGE } from "redux/constants";

function Header() {
  const imgLink = useSelector((state) => state.formReducer.headerImage);
  console.log("check imgLink>>>", imgLink);

  return (
    <header className="header-container p-5 z-0 h-auto w-full flex justify-center">
      <img
        className="header-img rounded-lg w-full h-auto"
        src={imgLink ? `${PATH_IMAGE}${imgLink}` : Banner}
        alt="BannerImg"
      />
    </header>
  );
}

export default Header;
